import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/apps/blogs/src/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blogs/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blogs/src/styles/tiptap.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-axiom/dist/webVitals/components.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"../../node_modules/geist/dist/sans.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-sans/Geist-Variable.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"GeistSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"../../node_modules/geist/dist/mono.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-mono/GeistMono-Variable.woff2\",\"variable\":\"--font-geist-mono\",\"adjustFontFallback\":false,\"fallback\":[\"ui-monospace\",\"SFMono-Regular\",\"Roboto Mono\",\"Menlo\",\"Monaco\",\"Liberation Mono\",\"DejaVu Sans Mono\",\"Courier New\",\"monospace\"],\"weight\":\"100 900\"}],\"variableName\":\"GeistMono\"}");
